import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import LaravelServiceBanner from '../images/laravel_service_banner.svg'
import LaravelServiceFeature from '../images/laravel_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const LaravelDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/laravel-web-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Laravel Web Development Services" description='eWise - Laravel Web Development Services'/>
<section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Laravel Web </strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-350 md:max-w-420 lg:max-w-530 m-auto lazyload" loading="lazy" data-src={LaravelServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">Think creative, not technical!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p className="pb-4">Laravel - a perfect platform when you don’t want to worry much about small technicalities! Focus on making your website applications look expressive and elegant!</p>
                <p>Imagine all the tools you need to create a perfect web application, Laravel has all of them. A PHP framework crafted for those who want to create and design more artistically. Get the grip over your design with a command line interface - Web Artisan.</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                    
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise’s view on Laravel<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Better performance due separation of business logic and presentation layer with MVC Architecture support</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create simple, beautiful and lightweight designs using dynamic content seeding with Template Engine</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Faster than other PHP frameworks with Eloquent Object Relational Mapping</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Solid web app security as password is never saved as a plain text</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Program your repetitive and daunting tasks with command-line interface - Artisan</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">No wasting of time with pre-defined modules and libraries for quick turnouts</li>
                        <li className="pl-10 relative">Immediate testing of multiple application units directly on the web browser</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-400 md:max-w-420 xl:max-w-530 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={LaravelServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise brings you a box full of magic!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Vapor</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Scale up on-demand with a server-less framework powered by AWS. It will take only a few seconds for your users to enjoy the scalability of your platform.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Forge</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Deploying and maintaining unlimited PHP applications on DigitalOcean, Linode, Vultr, Amazon shouldn’t be a nightmare anymore. Forge helps you in easy management and maintenance of your web applications.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Envoyer</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Worried about the downtime between the deployments and scalability? Achieve a zero downtime with Laravel Envoyer just anytime of the day without disturbing your users.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Horizon</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">A simple dashboard and code-driven configuration for your Laravel powered Redis queues. Easily manage and monitor key metrics of your applications.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Nova</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Let your administration panel be as simple, something you want to go back to as and when needed, and not a rocket science that would make it tough on the administrator.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Echo</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">
                            Make broadcasting events a hassle-free process. Subscribe to channels and listen for events broadcast through Websocket connections by Laravel.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Lumen</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Write your services as fast as it can be to support your applications. Utilize the micro-services and APIs at a lightning-fast speed</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Homestead</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">No need of a PHP, a web server, and any other server software on your local machine. Simply Homestead offers a simple, elegant way to manage and provision Virtual Machines.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Spark</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">From authentication to billing and invoices, if you don't wish to build them from scratch, Spark has got it covered! It offers numerous pre-built APIs that you can integrate with your applications.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Valet</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Work on limited amounts of RAM! Valet is a great option if you need flexible basics and prefer extreme speed on constrained RAM machines.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Mix</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Define your own asset pipeline with ease. Laravel Mix offers a simple API for defining Webpack build steps for your Laravel web application using CSS and JavaScript pre-processors.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Cashier</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Leave all your billing worries to the Cashier! From subscriptions, coupons to cancelling subscriptions grace periods, you can do it in a jiffy for your Laravel applications.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Dusk</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Automate your Laravel application on the browser whether it is working or not! You can automate several tasks without actually downloading a PHP framework.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Passport</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Grant an access token for each and every user once the authentication is done. Build a secure application in minutes with Passport.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Scout</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Search and browse your Laravel application with ease. Scout is a simple driver-based solution that enables a full-text search as well.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Socialite</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Simplify user logins! Allow your users to authenticate more easily through social media applications such as Facebook, Twitter and LinkedIn.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Telescope</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Get the better insight of your Laravel applications. Keep a watch on notifications, requests, and queued jobs, scheduled jobs everything with Telescope.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Laravel Tinker</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Enable the interaction with your database. The tinker console helps you to interact with your Laravel application from the command line in an interactive shell.</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why Hire eWise as Laravel application development expert!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">As a Laravel expert, we assure you that building your business application will be a hassle-free process for you. Our dedicated team of Laravel development ensures that they build you a unique, simple and creative web application that is not only easy on users but also convenient for administration. eWise makes sure the web app created optimizes your users’ productivity and efficiency!</p>
                    <p className="px-4">For any query on Laravel platform or web applications, you can connect with us.</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default LaravelDevelopment